import React from 'react'
import Layout from './components/Layout'

export default function about() {
    return (
        <Layout>
        <div>
            <h1>About Page</h1>
            <p>I am Adil, a web and mobile developer , holding a BSc Hons in Web and Mobile development from the University of the West of Scotland .Since I am passionate about everything that has to do with  AI and machine learning, I have enriched  my professional portfolio  with a postgraduate degree in data analytics.  Which enables me to treat large amounts of data in a fast and efficient way to be ultimately used in my web and mobile projects deployment.
</p>
            <p> I believe  that bridging the gap between the two degrees opens a lot of doors for me to be a jack of all trades ,as I have succeeded to indulge my skills set in different domains. For Example, health, business, digital investments as cryptocurrencies and NTFS  . Currently in the market  looking for the next chapter in my career .I am open for any full-time, part-time or contracted freelancing position.</p>            
        </div>
        </Layout>
    )
}
